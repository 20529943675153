<template>
    <cube-popup type="my-popup" position="bottom" ref="myPopup4" :zIndex="0">
        <div id="comdiv">
            <div class="cominputtop">
                <div class="closebut div" style="float: left;" @click="maskClick"><i class="iconfont iconquxiao1"></i></div>
                <div class="duibut div" style="float: right;" @click="mnpopupPopupOk"><i class="iconfont iconqueren"></i></div>
                <div style="clear:both"></div>
            </div>
            <div class="cominputdiv">
                <cube-input class="cominput" placeholder="书名" v-model="condition.name" clearable></cube-input>
                <cube-input class="cominput" placeholder="ISBN号" v-model="condition.isbn" clearable>
                    <q-r-code slot="append" class="closeicon aqt_ce_ve" @result="result"></q-r-code>
                </cube-input>
                <cube-input class="cominput" placeholder="作者名称" v-model="condition.author" clearable></cube-input>
                <cube-select class="cominput" v-model="condition.press_id"  :options="dataList" placeholder="出版社"></cube-select>
                <cube-select class="cominput" v-model="condition.culture_id"  :options="cultureClass" placeholder="文化分类"></cube-select>
                <cube-select class="cominput" v-model="condition.category_id"  :options="bookClass" placeholder="书籍分类"></cube-select>
                <span  @click="showStartTimePicker">
                    <cube-input class="cominput timeinput" style="float: left;" placeholder="开始时间" v-model="condition.start_time" readonly clearable></cube-input>
                </span>
                <span  @click="showEndTimePicker">
                    <cube-input class="cominput timeinput" style="float: right;" placeholder="结束时间" v-model="condition.end_time" readonly clearable></cube-input>
                </span>
                <div style="clear:both"></div>
            </div>
        </div>

    </cube-popup>
</template>
<script>
export default {
  components: {
    QRCode: resolve => {require(['./QRCode.vue'], resolve);},
  },
    props: {
        isShowPopup: {
            type: [Boolean],
            default: false,
        },
    },
	data() {
		return {
			confirm:{
				IsShow:false,
				message:''
            },
            mnPopupIs:false,
            selectPopupIs:false,
            listTypeIs:null,//用来判断级联
			dataList:[],
            bookClass:[],
            cultureClass:[],
			condition:{
				name:'',//书名
				category_id:'',//书籍分类id
				culture_id:'',//文化分类id
				press_id:'',//出版社id
				isbn:'',//书号
				author:'',//作者名称
				start_time:'',//出版开始时间
				end_time:'',//出版结束时间
            },
			condition1:{
                title:null,
                fid:null
            },
			condition2:{
                name:null
            },
            itemcondition:{},//当前点击的数据
			startTimePicker:null,//开始时间
			endTimePicker:null,//结束日期
		}
	},
	methods:{
		result(val) {
            this.condition.isbn = val
		},
        /*****************************************************************
         *
         * 获取出版社
         *
         *****************************************************************/
        queryDataPress(){
			const _this = this
            _this.$http.get(`api/web/v1/press/index`)
            .then(val =>{
				_this.dataList = val.data.map((item,index)=>{
					let arr = {
						text:item.press_name_lang.zh?item.press_name_lang.zh:item.press_name_lang.mn,
						value:item.press_id,
					}
					return arr
                })
                _this.dataList.unshift({
                    text:'全部',
                    value:null,
                });
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },

        /************************************************************
        *
        * 处理书籍分类和文化分类的数据格式
        *
        ************************************************************/
        handelSlugData(data, label,type=null) {
            const title = label
            data.map((el,ind) => {
                let kk = {
                    text:el.name_lang.zh ? `${title} ${el.name_lang.zh}` : `${title} ${el.name_lang.mn}`,
                    value:type?el.book_category_id:el.culture_type_id,
                }
                if(type=='book'){
                    this.bookClass.push(kk)
                }else{
                    this.cultureClass.push(kk)
                }
                let label = `${title}——`
                if (el.child.length !== 0) this.handelSlugData (el.child, label,type)
            })
        },
        /*****************************************************************
         *
         * 获取文化类型
         *
         *****************************************************************/
        queryDataCulture(){
			const _this = this
            _this.$http.get(`api/base/v1/web/slug/find?slug=all`)
            .then(val =>{
                _this.handelSlugData (val.data,' ');
                _this.cultureClass.unshift({
                    text:'全部',
                    value:null,
                });
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },

        /*****************************************************************
         *
         * 获取书籍分类
         *
         *****************************************************************/
        queryDataCategory(){
			const _this = this
            _this.$http.get(`api/web/v1/bookcategory/index`)
            .then(val =>{
                _this.handelSlugData (val.data,' ','book');
                _this.bookClass.unshift({
                    text:'全部',
                    value:null,
                });
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },

        /*****************************************************************
         *
         * 获取视频分类
         *
         *****************************************************************/
        queryDataVideosCategory(){
			const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/videoscategory/indexs?type=${_this.library_num}`)
            .then(val =>{
				_this.dataList = val.data.map((item,index)=>{
					let arr = {
						title:item.titlelang.mn?item.titlelang.mn:item.titlelang.zh,
						id:item.videos_category_id,
						child:[],
                        childBoolean:item.child
					}
					return arr
                })
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)
            })
        },
		loadDataVideosCategory(data){
            const _this = this
			_this.showToastMask(true)
            _this.$http.get(`api/web/v1/videoscategory/indexs?type=${_this.library_num}&fid=${data.id}`)
            .then(val =>{
				data.child = val.data.map((item,index)=>{
					let arr = {
						title:item.titlelang.mn?item.titlelang.mn:item.titlelang.zh,
						id:item.videos_category_id,
						child:[],
                        childBoolean:item.child
					}
					return arr
				})
				_this.showToastMask(false)
            }).catch(err =>{
				_this.showToastMask(false)

            })
        },


        /*****************************************************************
         *
         * 弹出层显示
         *
         *****************************************************************/
		showPopup(type) {
            let _this = this
            if(type == 'open'){
                _this.mnPopupIs = true
            }
            if(type == 'close'){
                if(_this.selectPopupIs==true){
                    _this.selectPopupIs=false
                }else{
                    _this.mnPopupIs = false
                    _this.$emit('on-close-click',false);
                }
            }
        },

        /*****************************************************************
         *
         * 选项层
         *
         *****************************************************************/
        selectPopup(type,listtype=null) {
            let _this = this
            if(type == 'open'){
                _this.itemcondition = {}
                _this.listTypeIs = listtype
                _this.dataList = []
                if(listtype == 'category'){
                    // 书籍分类
                    _this.queryDataCategory()
                }
                if(listtype == 'culture'){
                    // 文化类型
                    _this.queryDataCulture()
                }
                if(listtype == 'press'){
                    // 出版社
                    _this.queryDataPress()
                }
                if(listtype == 'videoscategory'){
                    // 视频分类
                    _this.queryDataVideosCategory()
                }
                _this.selectPopupIs = true
            }
            if(type == 'close'){
                _this.selectPopupIs = false
            }
        },

        /*****************************************************************
         *
         * 点击下一级
         *
         *****************************************************************/
		onChildClick(item,index){
            const _this = this
            _this.itemcondition = item
            if(_this.listTypeIs == 'category'){
                _this.loadDataCategory(item)
            }
            if(_this.listTypeIs == 'culture'){
                _this.loadDataCulture(item)
            }
            if(_this.listTypeIs == 'press'){

            }
            if(_this.listTypeIs == 'videoscategory'){
                // 视频分类
                _this.loadDataVideosCategory(item)
            }


		},

        /*****************************************************************
         *
         * 开始时间
         *
         *****************************************************************/
		showStartTimePicker() {
            let _this = this
			if (!_this.startTimePicker) {
				_this.startTimePicker = _this.$createDatePicker({
					title: 'Start Year',
                    startColumn:'year',
                    min: new Date(1600, 0, 12),
                    max:[new Date().getFullYear() + 1, 12, 31, 23, 59, 59],
                    value:new Date(),
                    columnCount:2,
					onSelect: _this.selectStartHandle,
					onCancel: _this.cancelStartHandle
				})
			}
			_this.startTimePicker.show()
		},
		selectStartHandle(date,selectedVal,selectedText){
            let _this = this
            _this.condition.start_time = selectedVal[0]+'—'+selectedVal[1]
		},
		cancelStartHandle(){
            let _this = this
            _this.condition.start_time = ''
        },

        /*****************************************************************
         *
         * 结束时间
         *
         *****************************************************************/
		showEndTimePicker() {
            let _this = this
			if (!_this.endTimePicker) {
				_this.endTimePicker = _this.$createDatePicker({
					title: 'End Year',
                    startColumn:'year',
                    min: new Date(1600, 0, 12),
                    max:[new Date().getFullYear() + 1, 12, 31, 23, 59, 59],
                    value:new Date(),
                    columnCount:2,
					onSelect: _this.selectEndHandle,
					onCancel: _this.cancelEndHandle
				})
			}
			_this.endTimePicker.show()
		},
		selectEndHandle(date,selectedVal,selectedText){
            let _this = this
            _this.condition.end_time = selectedVal[0]+'—'+selectedVal[1]
		},
		cancelEndHandle(){
            let _this = this
            _this.condition.end_time = ''
        },

        /*****************************************************************
         *
         * 点击搜索
         *
         *****************************************************************/
        mnpopupPopupOk(){
            let _this = this

            if(_this.condition.start_time){
                if(!_this.condition.end_time){
                    _this.aqtalert('请选择结束时间!')
                    return
                }else{
                    let ttts = _this.condition.start_time.split('—');
                    let ttte = _this.condition.end_time.split('—');
                    if(parseInt(ttts[0]) > parseInt(ttte[0])){
                        _this.aqtalert('开始时间必须小于结束时间!')
                        return
                    }else{
                        if(parseInt(ttts[1]) > parseInt(ttte[1])){
                            _this.aqtalert('开始时间必须小于结束时间!')
                            return
                        }
                    }
                }
            }else{
                if(_this.condition.end_time){
                    if(!_this.condition.start_time){
                        _this.aqtalert('请选择开始时间!')
                        return
                    }else{
                        let ttts1 = _this.condition.start_time.split('—');
                        let ttte1 = _this.condition.end_time.split('—');
                        if(parseInt(ttts1[0]) > parseInt(ttte1[0])){
                            _this.aqtalert('开始时间必须小于结束时间!')
                            return
                        }else{
                            if(parseInt(ttts1[1]) > parseInt(ttte1[1])){
                                _this.aqtalert('开始时间必须小于结束时间!')
                                return
                            }
                        }
                    }
                }
            }
            _this.$emit('on-search-click',JSON.parse(JSON.stringify(_this.condition)));
            _this.mnPopupIs = false
        },
        /*****************************************************************
         *
         * 点击遮罩层
         *
         *****************************************************************/
        maskClick(){
            this.$emit('on-mask-click');
        }

	},
	mounted(){
        this.queryDataPress()
        this.queryDataCulture()
        this.queryDataCategory()
    },
    computed: {
        library_num() {
            let vv = JSON.parse(localStorage.getItem("theme_color")) ? JSON.parse(localStorage.getItem("theme_color")).library_num : null;
            return vv;
        },
    },
    watch: {
        "$route.path": {
            // 表示对象中属性变化的处理函数，这个函数只能叫这个名字
            handler(newVal) {
                this.showPopup('close')
            },
            immediate: false,
            deep: true, // 表示开启深度监听
        },
        isShowPopup:{
            handler(newVal) {
                const component = this.$refs.myPopup4
                if(newVal){
                    component.show()
                }else{
                    component.hide()
                }
            },
            immediate: false,
            deep: true, // 表示开启深度监听
        }
    },
}
</script>
<style lang="less">
#comdiv{
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 15px 15px 0 0;
    .cominputdiv{
        .cominput{
            margin-bottom: 5px;
            text-align: left;
                        .closeicon{
                            width: 4rem;
                            height: 100%;
                            i{
                                color: #EDEDED;
                                font-size: 2rem;
                            }
                        }
        }
        .cube-select:after,.cube-input:after{
            border: 1px solid #ebebeb!important;
        }
        .timeinput{
            width: 49%;

        }
    }
    .cominputtop{
        width: 100%;
        height: 40px;
        line-height: 30px;
        .div{
            width: 20px;
            height: 100%;
            i{
                color: var(--themeColor);
                font-size: 26px;
            }
        }
        .closebut{
            i{
                font-size: 22px;
            }
        }
    }
}
.cube-picker,.cube-dialog{
    z-index: 99999999999!important;
}
</style>
